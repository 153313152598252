import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.visible) = $event)),
    "show-close": true,
    onClose: $setup.handleClose,
    class: _normalizeClass(['popup', $props.colorExit ])
  }, {
    default: _withCtx(() => [
      _createElementVNode("a", {
        href: $props.link,
        target: "_blank"
      }, [
        _createElementVNode("img", {
          src: $setup.currentImage,
          class: "w-full",
          alt: "Dialog Image"
        }, null, 8, _hoisted_2)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["modelValue", "onClose", "class"]))
}