import { ref, computed, onMounted } from 'vue';
export default {
  name: 'Popup',
  props: {
    image: {
      type: String
    },
    imageMobile: {
      type: String
    },
    colorExit: {
      type: String
    },
    link: {
      type: String
    },
    timeout: {
      type: String
    },
    frequency: {
      type: String
    }
  },
  setup(props) {
    const width = window.innerWidth;
    const visible = ref(false);
    const lastShown = ref(localStorage.getItem('lastDialogShown') || 0);
    const currentImage = computed(() => {
      return width > 1024 ? props.image : props.imageMobile;
    });
    const shouldShowDialog = computed(() => {
      const now = Date.now();
      const frequencyMs = props.frequency * 60 * 60 * 1000;
      return now - lastShown.value > frequencyMs;
    });
    onMounted(() => {
      if (shouldShowDialog.value) {
        setTimeout(() => {
          visible.value = true;
          localStorage.setItem('lastDialogShown', Date.now());
        }, props.timeout);
      }
    });
    const handleClose = () => {
      visible.value = false;
    };
    return {
      width,
      visible,
      currentImage,
      handleClose
    };
  }
};